import "./subject.css";
import union from "../asset/img/union.svg";
import subject from "../asset/img/subject.webp";
import ok1 from "../asset/vid/doitacchienluoc.mp4";

const Subject = () => {
  return (
    <div className="Subject">
      <div className="subject-container">
        <div className="line-title-subject">
          <img className="subject-union" src={union} />
          <span className="subject-title">
            ĐỐI TÁC CHIẾN LƯỢT 500AE - SHBET88
          </span>
        </div>
        <div className="subject-body">
          <div className="subject-item">
            <div className="subject-item-title">
              <video autoPlay loop controls muted className="birthday-vid-item">
                <source src={ok1} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subject;
