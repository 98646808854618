import logo from "./logo.svg";
import "./App.css";
import Banner from "./components/banner/Banner";
import ServerSelect from "./components/serverSelect/ServerSelect";
import Boss from "./components/boss/Boss";
import Data from "./components/data/Data";
import Subject from "./components/subject/Subject";
import Footer from "./components/footer/Footer";
import BackToTop from "./components/backToTop/BackToTop";
import React, { useState, useEffect } from "react";
import Loading from "./components/loading/Loading";
import Feedback from "./components/feedback/Feedback";
import Video from "./components/video/Video";
import Birthday from "./components/birthday/Birthday";
// import Menu from "./components/animationMenu/Menu";
import Header from "./components/header/Header";
import Coupon from "./components/coupon/Coupon";
import Proof from "./components/proof/Proof";
import Party from "./components/party/Party";

function App() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData().then((response) => {
      setData(response);
      setLoading(false);
    });
  }, []);

  const fetchData = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("Data fetched");
      }, 3000);
    });
  };

  return (
    <div className="App">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="background-header">
            <Header />
            <Banner />
            <BackToTop />
            {/* <Menu /> */}
            <div className="group-sv-boss">
              <div className="sv-boss-wrapper">
                <ServerSelect />
                <Boss />
              </div>
            </div>
          </div>
          <Data />
          <Subject />
          <Party />
          <Coupon />
          <Proof />
          <Feedback />
          <Video />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
