import "./serverSelect.css";
import btn from "../asset/img/bnt1-1.webp";

const ServerSelect = () => {
  const hiddenStyle = { display: "none" };
  return (
    <div className="server-select">
      {/* <div className="title-server">
        <img className="btn-sv" src={btn} alt="" />
        <span>Chọn chi nhánh để tham gia:</span>
      </div> */}
      <div className="server-list">
        <div className="col1">
          <a className="link2" href="https://t.me/trolytong500AE1">
            <span className="main-content">TRỢ LÝ TỔNG 500AE</span>
            <span className="truycap">Truy cập</span>
          </a>
        </div>
        <div className="col1">
          <a className="link2" href="https://t.me/dailong282868">
            <span className="main-content">1. BOSS ĐẠI LONG</span>
            <span className="truycap">Truy cập</span>
          </a>
          <a className="link2" href="https://t.me/HUYNHLAPBCR">
            <span className="main-content">2. BOSS CÔNG LẬP</span>
            <span className="truycap">Truy cập</span>
          </a>
          <a className="link2" href="https://t.me/BAOMONNHACAI1234">
            <span className="main-content">3. BOSS MINH QUÂN</span>
            <span className="truycap">Truy cập</span>
          </a>
          <a className="link2" href="https://t.me/LongPhamLXR999">
            <span className="main-content">4. BOSS LONG PHẠM</span>
            <span className="truycap">Truy cập</span>
          </a>
          <a className="link2" href="https://t.me/nhaky6869">
            <span className="main-content">5. BOSS NHÃ KỲ</span>
            <span className="truycap">Truy cập</span>
          </a>
          <a className="link2" href="https://t.me/DIEUKHACBIET6666">
            <span className="main-content">6. BOSS HOÀNG PHÚC</span>
            <span className="truycap">Truy cập</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ServerSelect;
